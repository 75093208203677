"use strict";

;
(function ($) {

  var loadJS = function loadJS(url) {
    var r = document.getElementsByTagName("script")[0],
        s = document.createElement("script");
    s.src = url;
    r.parentNode.insertBefore(s, r);
  };

  //Карта в разделе Где купить
  if (window.innerWidth > 900 && typeof google == 'undefined' && $('.contacts__item--map').length) {
    loadJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyDrc4H8T3Z8S688U47tz35KIn0TQUt_y7o&v=3.exp&sensor=false&callback=tagreeInitMap');
  }

  var map;

  window.tagreeInitMap = function () {

    $('.contacts__item--map').each(function () {
      var $this = $(this);
      var coords = $this.data('coords');

      map = new google.maps.Map($this[0], {
        center: coords,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        panControl: false,
        zoom: 15,
        scrollwheel: false
      });
      var marker = new google.maps.Marker({
        map: map,
        position: coords,
        icon: $this.data('marker')
      });
    });
  };

  AOS.init();

  $('.home-slide__desc').addClass('dotdotdot-content').dotdotdot();

  //Autosize textarea
  $('textarea.js-auto-size').textareaAutoSize();

  //Home slider
  $('.home-slider').on('init', function (event, slick) {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      $('.home-slider').addClass('outdated');
    }
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      $('.home-slider').addClass('outdated');
    }
    slick.$slider.closest('.home-slider-w').find('.home-slider-count__all').text(slick.slideCount);
  }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    slick.$slider.closest('.home-slider-w').find('.home-slider-count__cur').text(nextSlide + 1);
    if (!$('.home-slider').hasClass('outdated')) {
      $('.home-slider .slick-arrow').addClass('disable');
      setTimeout(function () {
        $('.home-slider .slick-arrow').removeClass('disable');
      }, 1400);
    }
  })
  // .on('afterChange', function(event, slick, currentSlide) {
  // $('.home-slide').removeClass('hide');
  // })
  .slick({
    slide: '.home-slide',
    fade: true
  });

  //Services slider
  $('.services-slider').slick({
    slide: '.services-slider__item',
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: true,
    autoplay: true,
    responsive: [{
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });

  //Where Cargo Popup
  $('.where-cargo, .where-cargo-mobile').magnificPopup({
    type: 'inline',
    mainClass: 'mfp-fade',
    overflowY: 'scroll',
    callbacks: {
      open: function open() {
        $('.header').addClass('mfp-open-p');
      },
      close: function close() {
        $('.header').removeClass('mfp-open-p');
      }
    }
  });

  //Order Popup
  $('.js-open-order').magnificPopup({
    type: 'inline',
    mainClass: 'mfp-fade',
    overflowY: 'scroll',
    callbacks: {
      open: function open() {
        $('.header').addClass('mfp-open-p');
      },
      close: function close() {
        $('.header').removeClass('mfp-open-p');
      }
    }
  });

  //Init custom scroll
  $(".nano").nanoScroller();

  //Mobile Navigation
  $("#mobile-menu").mmenu({
    "navbar": {
      title: ''
    }
  });

  var API = $("#mobile-menu").data("mmenu");
  $("#mobile-menu .mm-panel").prepend('<button type="button" class="mobile-menu__close"></button>');

  var scrollTop = 0;

  $(document)
  //Close map info
  .on('click', '.map-close', function () {
    $('.map, .map-info').removeClass('open');
    $('html').removeClass('no-scroll');
  })
  //Close mobile navigation
  .on('click', '.mobile-menu__close', function () {
    API.closeAllPanels();
    API.close();
  }).on('click', '.delivery-list__item', function () {
    var $this = $(this),
        $target = $($this.closest('.delivery-list').data('target')),
        value = $this.data('val');

    $target.val(value).addClass('filled');
    $this.closest('.form__row').find('.delivery-list').removeClass('show');
  }).on('keyup click', '.delivery-field', function () {
    var $this = $(this),
        $list = $this.closest('.form__row').find('.delivery-list');
    if ($this.val().length >= 2) {
      $list.addClass('show');
    } else {
      $list.removeClass('show');
    }
    $(document).off('click.outlist').on('click.outlist', function (e) {
      if ($(e.target).closest('.delivery-list').length == 0 && !$(e.target).hasClass('delivery-list')) {
        $('.delivery-list').removeClass('show');
      }
    });
  }).on('click', '.js-scroll-down', function () {
    $('html').velocity("scroll", { duration: 750, offset: $(window).height() - 82 });
  }).on('click', '.js-toggle-menu', function () {
    if ($(window).width() <= 1000 || $('html').hasClass('bx-touch')) {
      API.open();
    } else {
      var $html = $('html');
      if ($html.hasClass('menu-opened')) {
        $html.removeClass('menu-opened');
        window.scrollTo(0, scrollTop);
      } else {
        scrollTop = $(window).scrollTop();
        $html.addClass('menu-opened');
      }
    }
  }).on('mouseenter', '.navigation__link', function () {
    var $this = $(this);
    if ($this.closest('.navigation__inner').length != 0) return false;
    $('.navigation__item').removeClass('open');
    $this.closest('.navigation__item').addClass('open');
  }).on('click', '.js-close-sys-msg', function () {
    $('.system-msg').removeClass('show');
  }).on('click', '.js-toggle-accord', function () {
    var $accord = $(this).closest('.js-accord');
    if ($accord.find('.js-accord__content').hasClass('velocity-animating')) return false;
    $('.js-accord').not($accord).removeClass('open').find('.js-accord__content').velocity('slideUp');

    if ($accord.hasClass('open')) {
      $accord.removeClass('open').find('.js-accord__content').velocity('slideUp');
    } else {
      $accord.addClass('open').find('.js-accord__content').velocity('slideDown');
    }
  });

  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 0) $('.header').addClass('header--white');else $('.header').removeClass('header--white');
  });

  $(window).on('resize', function () {
    $('.dotdotdot-content').trigger("update.dot");
  });
})(jQuery);